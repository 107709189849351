#top-bar
{
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100;
    border-bottom: 1px solid #ccc;
    position: fixed;
    width: 100%;
    top: 0;
    min-height: 100px;

    &.show-overlay:after
    {
        content: "";
        position: fixed;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    & > .row {
        color: white;
    }

    .row
    {
        position: relative;

        .col
        {
            display: flex;
            align-items: center;
        }

        .left
        {
            .ptmd-logo
            {
                @include media-breakpoint-up(md)
                {
                    margin: 0 50px;

                    img
                    {
                        width: 100px;
                        height: 100px;
                    }
                }

                img
                {
                    width: 75px;
                    height: 75px;
                }
            }
        }

        .middle
        {
            justify-content: center;
            flex-direction: column;
            position: static;

            dl, dd {
                margin-bottom: 0;
            }

            img
            {
                margin-top: 5px;
            }

            .h-100 {
                height: 100%;
                color: inherit;
            }

            .h-100:hover {
                text-decoration: none;
            }

            .weeks {
                max-width: 100%;
                max-height: 4em;
            }

            .tagline-image
            {
                width: 150px;
                margin: 0 auto;
            }

            .navigation-container
            {
                @include font-playfair-display-sc();
                font-size: 1.25rem;

                nav
                {
                    padding: 0;
                    position: static;

                    a,
                    button.nav-link
                    {
                        color: inherit;
                        font-size: 1.1rem !important;
                        padding-top: 0;
                        padding-bottom: 0;
                    }

                    .nav-link.staff {
                        color: #DC143C;
                    }

                    .dropdown
                    {
                        > button
                        {
                            background-color: transparent !important;
                            border: none !important;
                            font-size: 1.25rem;
                            box-shadow: none !important;
                        }

                        .dropdown-menu
                        {
                            margin: 0;
                            background-color: black;
                            position: absolute;

                            .dropdown-item
                            {
                                background-color: inherit;
                                transition: padding-left 100ms ease;

                                &:hover
                                {
                                    padding-left: 30px;
                                }
                            }
                        }
                    }

                    .big-dropdown
                    {
                        position: absolute;
                        left: 0;
                        width: 100%;
                        z-index: 100;
                        display: none;
                        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);

                        @include media-breakpoint-up(lg)
                        {
                            height: 80vh;
                            align-items: center;
                        }

                        &.open
                        {
                            display: block;
                        }
                    }

                    .webshop-nav-dropdown
                    {
                        background-color: rgba(0, 0, 0, 0.9);
                        overflow-y: scroll;

                        @include media-breakpoint-up(lg)
                        {
                            overflow-y: hidden !important;
                        }

                        .inner-webshop-nav-dropdown
                        {
                            max-width: 350px;
                            margin: 0 auto;
                            padding: 20px;

                            @include media-breakpoint-up(lg)
                            {
                                max-width: 1000px !important;
                                max-height: 100% !important;
                            }
                        }

                        .webshop-nav-column {
                            position: relative;

                            @include media-breakpoint-up(lg)
                            {
                                margin-bottom: 20px;
                                margin-top: 20px;
                            }

                            .inner-webshop-nav-column {
                                height: 100%;
                                position: relative;
                                background-position: center center;
                                background-size: cover;

                                @include media-breakpoint-down(md)
                                {
                                    background-image: none !important;
                                }

                                &.inner-product-group {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    cursor: pointer;

                                    .product-group-name-container {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 100%;

                                        h3.product-group-name {
                                            text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
                                            font-weight: bold;
                                            position: relative;
                                            z-index: 1;
                                            font-size: 1.5rem;
                                            text-align: center;

                                            @include media-breakpoint-up(lg)
                                            {
                                                opacity: 0;
                                            }
                                        }
                                    }
                                }

                                &.webshop-item {
                                    background-color: white;
                                    padding: 5px;
                                    display: flex;
                                    flex-direction: column;

                                    .item-image {
                                        display: block;
                                        margin: 0 auto;
                                    }

                                    .item-description {
                                        text-align: center;
                                    }

                                    .item-meta {
                                        margin-top: auto;

                                        span {
                                            display: block;
                                        }
                                    }
                                }

                                &.inner-product-group:after {
                                    content: "";
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    z-index: 0;
                                    transition: background-color 200ms ease;
                                }

                                &.inner-product-group:hover
                                {
                                    &:after {
                                        background-color: rgba(255, 255, 255, 0.5);
                                    }

                                    .product-group-name-container h3.product-group-name
                                    {
                                        opacity: 1;
                                    }
                                }
                            }

                            @include media-breakpoint-up(lg)
                            {
                                &.product-group:after {
                                    content: "";
                                    display: block;
                                    padding-bottom: calc(100% - #{$grid-gutter-width});
                                }
                            }
                        }
                    }
                }
            }

            .language-selection > div {
                padding: 0 0.2em;
                margin: 0.2em;
                border-radius: 5px;
                cursor: pointer;

                &.active, &:hover {
                    background-color: rgba(255, 255, 255, 0.3);
                }
            }
        }

        .right
        {
            justify-content: center;
            align-items: flex-end;
            font-size: 2.5rem;
            position: static;
            flex-direction: column;

            > div
            {
                margin-right: 20px;
                display: flex;

                &.lower
                {
                    min-height: 40px;
                }
            }

            img
            {
                width: 40px;
                height: 40px;
            }

            .right-icon
            {
                text-align: center;
                font-size: 1.5rem !important;
                margin-right: 25px;

                a {
                    color: inherit;
                }
            }

            .logout-icon
            {
                a {
                    color: inherit;
                    text-decoration: none;

                    .name {
                        font-size: 1rem;
                    }
                }
            }

            .nav-opener-container
            {
                top: 0;
                right: 0;
                > span {
                    cursor: pointer;
                    display: inline-block;
                    &:not(:last-child) {
                        margin-right: 0.5em;
                    }
                }
            }

            .shopping-cart-icon
            {
                position: relative;
                margin-right: 25px;
                cursor: pointer;
                color: inherit;

                .cart-badge
                {
                    position: absolute;
                    top: 0;
                    right: -15px;

                    border-radius: 50%;
                    background-color: red;
                    font-size: 1rem;
                    width: 25px;
                    height: 25px;
                    text-align: center;
                    line-height: 25px;
                }
            }
        }
    }
}
