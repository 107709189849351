$max-height-desktop: 600px;

.cart-dropdown
{
    background-color: white;
    display: none;
    flex: 1;

    &.open
    {
        display: block;
    }

    @include media-breakpoint-up(lg) {
        max-height: $max-height-desktop;
    }

    .outer-cart-container
    {
        overflow-y: auto;
        margin-bottom: 10px;
    }

    .cart-container
    {
        max-width: 1200px;
        margin: 0 auto;
    }

    .total-price
    {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding: 10px 15px;
        margin: 0;
    }

    .product-line:last-child
    {
        border-bottom: none;
    }

    .cart
    {
        $height: calc(100vh - 75px);

        min-height: $height;
        height: $height;

        @include media-breakpoint-up(lg)
        {
            min-height: $max-height-desktop;
            max-height: $max-height-desktop;
            height: $max-height-desktop;
        }
    }
}

.cart
{
    padding: 30px 0;
    display: flex;
    flex-direction: column;

    .cart-header
    {
        @include font-playfair-display-sc();
        text-align: center;
    }

    > .total-price
    {
        > div
        {
            flex-direction: column;
        }
    }

    > .cart-container
    {
        flex: 1;
        overflow-y: auto;
    }

    .cart-container
    {
        width: 100%;

        .transport {
            display: flex;
            line-height: 1;
            width: 3em;
            height: 2em;
            justify-content: center;
            background: url('/images/transport.png') no-repeat center center / contain;
        }
    }

    > .row > div
    {
        display: flex;
        align-items: center;
    }

    .item-image
    {
        max-width: 100%;
        margin: 0 auto;
    }

    .item-text
    {
        h4
        {
            @include font-playfair-display-sc();
        }
    }

    .item-price
    {
        justify-content: flex-end;
    }

    .total-price-table
    {
        @include font-futura-book();
        font-size: 1rem;
        width: 100%;

        tr > td:last-child
        {
            text-align: right;
        }
    }

    .product-line
    {
        margin: 5px 0;
        border-bottom: 1px solid black;
        padding: 5px 0;
    }
}
