#webshop
{
    h2
    {
        text-transform: uppercase;
        line-height: 48px;
        font-size: 40px;
        text-align: center;
        margin-bottom: 10px;
    }

    .flex-center {
        display: flex;
        align-items: center;
    }

    .category-name
    {
        color: white;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
        padding: 275px 50px 50px;
        margin-top: -200px;
        background: no-repeat center center;
        background-size: cover;
        @include font-playfair-display-sc();
    }

    .webshop-column
    {
        margin-bottom: 10px;
        position: relative;

        .inner-webshop-column
        {
            height: 100%;
            position: relative;

            &.inner-product-group
            {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .product-group-name-container
                {
                    width: 100%;
                    height: 90%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;

                    h3.product-group-name
                    {
                        color: white;
                        text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
                        font-weight: bold;
                        position: relative;
                        z-index: 1;
                        margin-top: -50px;
                    }
                }
            }

            &.webshop-item
            {
                background-color: white;
                padding: 5px;
                display: flex;
                flex-direction: column;
                cursor: pointer;

                .item-image
                {
                    display: block;
                    margin: 0 auto;
                }

                .item-description
                {
                    text-align: center;
                }

                .item-meta
                {
                    margin-top: auto;

                    span
                    {
                        display: block;

                        &:not(:last-child)
                        {
                            font-size: 0.8rem;
                        }

                        &:last-child
                        {
                            text-align: center;
                            font-weight: bold;
                        }
                    }
                }
            }

            &.inner-product-group:after
            {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 0;
                transition: background-color 200ms ease;
            }

            &.inner-product-group:hover:after
            {
                background-color: rgba(0, 0, 0, 0.3);
            }
        }

        &.product-group:after
        {
            content: "";
            display: block;
            padding-bottom: calc(100% - #{$grid-gutter-width});
        }
    }

    .filters
    {
        border-right: 1px solid #ccc;
        padding-right: 1rem;

        .category
        {
            color: black;
            font-weight: bold;
        }

        .productgroup
        {
            color: black;
            padding-left: 2rem;
            font-size: 0.9rem;
            list-style: none;

            a
            {
                color: black;
            }
        }

        .filter-button
        {
            color: white;
            background-color: black;
            border: 2px solid white;
            display: inline-block;
            padding: 5px 10px;
            font-size: 1.25rem;
            margin: 0 auto;
            text-decoration: none;
            border: 1px solid black;
            transition-property: color, background-color;
            transition-duration: 200ms;
            transition-timing-function: ease;

            &:hover
            {
                background-color: white;
                color: black;
            }
        }

        .sub-filters
        {
            .sub-filter-button
            {
                display: block;
                text-decoration: none;
                width: 100%;
                text-align: left;
                position: relative;
                color: black;
                font-weight: bold;
                padding: 0;
                margin-top: 0.5em;

                svg
                {
                    right: 0;
                    top: 0.25em;
                    position: absolute;
                }
            }

            .sub-filter-filter
            {
                border: 1px solid #ccc;
                margin: 0.5em -0.25em 1em;
                padding: 0.25em;

                .form-group {
                    margin-bottom: 0;
                }
            }

            .small-categories
            {
                @include media-breakpoint-up(md)
                {
                    display: none;
                }
            }
        }
        .large-categories
        {
            display: none;
            border-top: 1px solid #ccc;
            padding-top: -0.25em;
            margin-top: 0.5em;

            @include media-breakpoint-up(md)
            {
                display: block;
            }
        }
    }

    .breadcrumb
    {
        @include font-playfair-display-sc();
        font-size: 1.1rem;
        background-color: white;

        span.separator
        {
            display: inline-block;
            margin: 0 10px;
        }
    }

    .single-item
    {
        padding: 10px;
        @include media-breakpoint-up(lg)
        {
            padding: 50px;
        }

        .product-meta-column
        {
            align-items: flex-start;

            .outer-product-meta
            {
                border-bottom: 1px solid #666;
                margin-bottom: 20px;
            }

            .product-meta
            {
                margin-bottom: 20px;

                tr > :not(:last-child)
                {
                    padding-right: 10px;
                    text-align: right;
                }
            }
        }

        .product-image
        {
            max-width: 100%;
            margin: 0 auto;
            display: block;
            max-height: 500px;
        }

        .product-title
        {
            @include font-playfair-display-sc();

            text-align: center;

            @include media-breakpoint-up(lg)
            {
                text-align: left;
            }

            span
            {
                font-size: 1.5rem;
                color: grey;
            }
        }

        .description h3,
        {
            @include font-playfair-display-sc();
        }

        .description
        {
            margin: 20px 0;
        }

        .gray-box
        {
            border: 1px
        }

        .cart-section
        {
            margin: 40px 0;

            select
            {
                display: block;
                border: 1px solid #333;
                color: black;
                background-color: white;
                padding: 10px;
                text-align: center;
                width: 100%;
                margin: 10px 0;
                font-size: 1.1rem;
                cursor: pointer;
                outline: none;
            }
        }

        .checks-list
        {
            max-width: 500px;
            margin: 0 auto;
        }
    }
}
