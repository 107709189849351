@mixin font-montserrat()
{
    font-family: montserrat, sans-serif;
}

@mixin font-open-sans()
{
    font-family: open-sans, sans-serif;
}

@mixin font-futura-book()
{
    font-family: "Futura Book", sans-serif;
}

@mixin font-unthrift-first()
{
    font-family: 'Unthrift First', serif;
}

@mixin font-playfair-display-sc()
{
    font-family: 'Playfair Display SC', serif;
}

.brown-highlight-text
{
    color: #A37F5A;
}

.montserrat
{
    @include font-montserrat();
}

.open-sans
{
    @include font-open-sans();
}

.future-book
{
    @include font-futura-book();
}

.unthrift-first-font
{
    @include font-unthrift-first();
}

.playfair-display-sc
{
    @include font-playfair-display-sc();
}

.grass-green
{
    color: #08b413;
}
