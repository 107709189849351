@font-face
{
    font-family: 'Futura Book';
    font-weight: 400;
    src: url('/fonts/FtraBk__.ttf') format('truetype');
}

@font-face
{
    font-family: 'Futura Book';
    font-weight: 200;
    src: url('/fonts/FtraLt__.ttf') format('truetype');
}

@font-face
{
    font-family: 'Futura Book';
    font-weight: 600;
    src: url('/fonts/FtraMd__.ttf') format('truetype');
}

@font-face
{
    font-family: 'Unthrift First';
    src: url('/fonts/Aring Typeface - Unthrift First.ttf') format('truetype');
}
