// Vendors
@import '~bootstrap/scss/bootstrap';

// Override Bootstrap
//@import 'variables';

@import 'mixin';

// Custom CSS
@import 'fonts';
@import 'text';
@import 'top-bar';
@import 'webshop';
@import 'cart';
@import 'button';
@import 'staff';
@import 'catalog';
@import 'print';

html, body {
    min-height: 100%;
}

body
{
    font-family: montserrat, sans-serif;
    background: black url(/images/background.jpg) no-repeat center center/cover fixed;
}

main {
    padding-top: 100px;
}

main > section {
    background-color: #FFF;
    padding: 0.5em;

    .item-image
    {
        max-width: 100px;
    }

    img {
        max-width: 100%;
    }
}

.checkbox-col
{
    display: flex;
    align-items: center;
}

.clickable
{
    cursor: pointer;
}

.no-transition
{
    transition: none;
}

aside.notifications {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    .notification {
        background-color: white;
        margin: 0.5em;
        border-radius: 5px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
        opacity: 0.9;

        &:hover {
            opacity: 1;
        }

        div {
            padding-right: 0;

            p {
                padding: 0.25rem 0.5rem;
                margin: 0;
                font-size: 0.875rem;
            }

            button {
                margin-right: -1px;
                display: inline-block;
                height: 100%;
            }
        }
    }
}

@include media-breakpoint-up(lg)
{
    .no-collapse-lg
    {
        display: block !important;
        height: auto !important;
        visibility: visible !important;
    }
}
